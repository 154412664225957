<template>
    <div class="form-control" :class="wrapperClasses">
        <label class="cursor-pointer label">
            <template v-if="checkboxLeft">
                <input
                    type="checkbox"
                    class="mr-4 bg-white checkbox input-bordered"
                    :class="{ 'input-error': hasError }"
                    v-bind="$attrs"
                    :checked="modelValue"
                    @change="$emit('update:modelValue', $event.target.checked)"
                />
                <span v-show="hasLabel" class="label-text">
                    <slot name="label" />
                </span>
            </template>
            <template v-else>
                <span v-show="hasLabel" class="label-text">
                    <slot name="label" />
                </span>
                <input
                    type="checkbox"
                    class="ml-4 bg-white checkbox input-bordered"
                    :class="{ 'input-error': hasError }"
                    v-bind="$attrs"
                    :checked="modelValue"
                    @change="$emit('update:modelValue', $event.target.checked)"
                />
            </template>
        </label>
        <div v-show="hasError" class="label">
            <span class="label-text-alt text-error">
                <slot name="error" />
            </span>
        </div>
    </div>
</template>

<script>
import { hasSlot } from '@/helpers.js';

export default {
    name: 'Checkbox',
    inheritAttrs: false,
    props: {
        checkboxLeft: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Boolean,
            required: true,
        },
        wrapperClasses: {
            type: String,
        },
    },
    emits: ['update:modelValue'],
    computed: {
        hasLabel() {
            return hasSlot(this.$slots, 'label');
        },
        hasError() {
            return hasSlot(this.$slots, 'error');
        },
    },
};
</script>
